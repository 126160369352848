const draggable = {
  dragSetup() {
    const closestDialog = this.$refs.draggableModal.$el
    if (closestDialog === null) return

    closestDialog.style.left = `${Math.min(
      parseInt(closestDialog.style.left, 10),
      window.innerWidth * 1.5 - closestDialog.getBoundingClientRect().width,
    )}px`

    closestDialog.style.top = `${Math.min(
      parseInt(closestDialog.style.top, 10),
      window.innerHeight * 1.5 - closestDialog.getBoundingClientRect().height,
    )}px`
  },
  dragStart(event) {
    const logger = console
    const closestDialog = this.$refs.draggableModal.$el
    const { dragger } = this

    dragger.element = closestDialog
    dragger.mouseStartX = event.clientX
    dragger.mouseStartY = event.clientY
    dragger.elementStartX = dragger.element.getBoundingClientRect().left
    dragger.elementStartY = dragger.element.getBoundingClientRect().top
    dragger.oldPosition = dragger.element.style.position
    dragger.element.style.position = 'fixed'
    dragger.element.style.margin = 0
    dragger.oldTransition = dragger.element.style.transition
    dragger.element.style.transition = 'none'

    logger.info('Dragging has begun')
  },
  dragging(event) {
    const { dragger } = this
    if (dragger.element === undefined) return

    dragger.element.style.left = `${Math.min(
      Math.max(dragger.elementStartX + event.clientX - dragger.mouseStartX, 0),
      window.innerWidth * 1.5 - dragger.element.getBoundingClientRect().width,
    )}px`

    dragger.element.style.top = `${Math.min(
      Math.max(dragger.elementStartY + event.clientY - dragger.mouseStartY, 0),
      window.innerHeight * 1.5 - 82,
    )}px`
  },
  finishDragging() {
    const { dragger } = this
    if (dragger.element === undefined) return

    dragger.element.style.position = dragger.oldPosition
    dragger.element.style.transition = dragger.oldTransiition
    dragger.element = undefined
  },
}

export default draggable
