<template>
  <v-dialog
    v-model="visible"
    class="tearsheets"
    scrollable
    max-width="750px"
    hide-overlay
    persistent
    no-click-animation
  >
    <v-card
      v-if="currentRecord"
      ref="draggableModal"
      class="draggable-dialog"
      max-height="60%"
      max-width="70%"
      @mousedown="dragStart"
      @mousemove="dragging"
    >
      <v-card-title>
        <span>
          Creatives
        </span>

        <v-card-actions>
          <v-btn
            :ripple="false"
            icon
            @click.stop="close()"
          >
            <v-icon color="brightblue">
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-divider class="mb-6" />

      <v-card-text>
        <div
          v-if="getHelp"
          class="description mb-6"
        >
          Find the creative's ID, enter a URL that is eligible to serve this line item, and submit your tearsheet request. You will receive the tearsheet's URL in response.
        </div>

        <div class="current-record pb-3 d-flex justify-space-between align-center">
          <div class="subtitle-1 font-weight-bold mr-2">
            {{ currentRecord.lineItemName }}
          </div>

          <div class="current-record-code">
            {{ currentRecord.code }}
          </div>
        </div>

        <div
          v-if="isLoadingLineItemCreative"
          class="loader"
        >
          <v-progress-circular
            :size="70"
            color="#055289"
            indeterminate
          />
        </div>

        <div
          v-if="viewCreatives"
          class="creatives"
        >
          <v-card
            v-for="creative of creatives"
            :key="creative.id"
            class="creative pa-3"
            flat
          >
            <v-card-title
              :class="`subtitle-2 pa-0 font-weight-bold ${creative.previewUrl ? 'darkestblue' : 'coolgray'}--text`"
            >
              <a
                v-if="creative.previewUrl"
                :href="creative.previewUrl"
                target="_blank"
              >
                {{ creative.name }}
              </a>

              <span v-else>
                {{ creative.name }}
              </span>

              <div class="creativeId gray--text font-weight-regular">
                {{ creative.id }}
              </div>
            </v-card-title>

            <v-card-text class="tearsheet-form d-flex">
              <v-text-field
                v-model="tearsheetInputText[creative.id]"
                class="my-1"
                clear-icon="mdi-close-circle"
                clearable
                flat
                solo
                type="url"
                :label="tearsheetInputLabel"
                hide-details
                prepend-inner-icon="link"
                :loading="isLoadingLineItemTearsheet"
                @keydown.up="onArrowUp()"
                @keydown.down="onArrowDown()"
              />

              <v-btn
                :loading="isLoadingLineItemTearsheet"
                class="ms-4 my-1"
                color="primary"
                @click="submitTearsheet(creative)"
              >
                Get Tearsheet
              </v-btn>
            </v-card-text>

            <div
              v-if="tearsheets && tearsheets[creative.id]"
              class="tearsheet mt-3 pa-3 justify-space-between"
            >
              <div v-if="tearsheets[creative.id].tearsheetUrl">
                <textarea
                  :ref="creative.id"
                  class="border-heavy tearsheet-url"
                  name="tearsheet"
                  rows="3"
                  type="url"
                  :value="tearsheets[creative.id].tearsheetUrl"
                />
              </div>

              <v-card-actions class="justify-end">
                <v-btn
                  color="coolgray offwhite--text"
                  :href="tearsheets[creative.id].tearsheetUrl"
                  target="_blank"
                  outlined
                >
                  <v-icon>
                    open_in_new
                  </v-icon>
                  <span class="ml-1">Open</span>
                </v-btn>

                <v-btn
                  color="coolgray"
                  outlined
                  @click="copyTearsheetUrl(creative.id)"
                >
                  <v-icon>file_copy</v-icon>
                  <span class="ml-1">Copy</span>
                </v-btn>
              </v-card-actions>

              <v-snackbar
                v-model="showCopyMessage"
              >
                {{ copied ? 'Copied' : 'Failed to copy' }}
                <v-btn
                  color="primary"
                  @click="showCopyMessage = false"
                >
                  Close
                </v-btn>
              </v-snackbar>
            </div>
          </v-card>
        </div>

        <div
          v-if="noCreativeData"
          class="empty-state"
        >
          No creative data found for the selected line item.
        </div>

        <div
          v-if="didLoadLineItemTearsheetFail"
          class="error-message"
        >
          <v-icon>error_outline</v-icon>
          Failed to load this line item's creatives. Please try again later.
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import draggable from 'src/utils/draggable'
import render from 'src/utils/render'

const { mapState, mapActions } = createNamespacedHelpers('delivery')

export default {
  name: 'TearsheetModal',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    tearsheetInputLabel: 'Site URL',
    tearsheetInputText: {},
    showCopyMessage: false,
    copied: false,
    getHelp: false,
    dragger: {},
  }),
  computed: {
    visible: {
      get() {
        return this.isCreativeModalVisible
      },
      set(value) {
        this.$store.commit('delivery/isCreativeModalVisible', value)
      },
    },
    ...mapState({
      publisher: (state) => state.publisher,
      currentRecord: (state) => state.currentRecord,
      creatives: (state) => state.creatives,
      tearsheets: (state) => state.tearsheets,
      isCreativeModalVisible: (state) => state.isCreativeModalVisible,
      isLoadingLineItemCreative: (state) => state.isLoadingLineItemCreative,
      didLoadLineItemCreativeFail: (state) => state.didLoadLineItemCreativeFail,
      isLoadingLineItemTearsheet: (state) => state.isLoadingLineItemTearsheet,
      didLoadLineItemTearsheetFail: (state) => state.didLoadLineItemTearsheetFail,
    }),
    viewCreatives() {
      const {
        creatives,
        didLoadLineItemCreativeFail,
        isLoadingLineItemCreative,
      } = this

      return isLoadingLineItemCreative === false
        && didLoadLineItemCreativeFail === false
        && creatives.length > 0
    },
    noCreativeData() {
      const {
        creatives,
        isLoadingLineItemCreative,
        didLoadLineItemCreativeFail,
      } = this
      const requestComplete = isLoadingLineItemCreative === false
      const requestSucceeded = didLoadLineItemCreativeFail === false
      const noResults = creatives.length === 0

      return requestComplete && requestSucceeded && noResults
    },
  },
  created() {
    document.addEventListener('mouseup', this.finishDragging)
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.finishDragging)
  },
  methods: {
    close() {
      this.visible = false
    },
    ...mapActions(['getCreatives', 'getTearsheet']),
    ...draggable,
    onArrowUp() {
      render.keepHighlightedElementInScrollView.call(this)
    },
    onArrowDown() {
      render.keepHighlightedElementInScrollView.call(this)
    },
    submitTearsheet(creative) {
      const { id: creativeId } = creative
      const siteUrl = this.tearsheetInputText[creativeId]

      const { currentRecord } = this
      const { code: lineItemId, networkCode } = currentRecord

      this.getTearsheet({ creativeId, lineItemId, networkCode, siteUrl })
    },
    onCopySuccess() {
      this.copied = true
      this.showCopyMessage = true
    },
    onCopyError() {
      this.copied = false
      this.showCopyMessage = true
    },
    copyTearsheetUrl(creativeId) {
      const el = this.$refs[creativeId]

      if (!el.length) return this.onCopyError()

      el[0].select()

      document.execCommand('copy')

      return this.onCopySuccess()
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~src/styles/variables";

  .draggable-dialog {
    left: 15%;
    position: fixed;
    top: 69px;
  }

  .creative {
    border: 3px solid $color-monochrome-offwhite !important;
    position: relative;
    left: 0%;
  }

  .tearsheet {
    background: $color-monochrome-offwhite;
    border-radius: 6px;
  }

  .creativeId,
  .current-record-code,
  .tearsheet-url {
    letter-spacing: 0.1ch !important;
  }

  ::v-deep.v-card {
    border-radius: 6px;
    border-width: 0;
  }

  ::v-deep.v-card__title {
    font-size: 18px;
    letter-spacing: 0.51px;
    line-height: 22px;
    justify-content: space-between;
  }

  ::v-deep.v-card__text {
    padding: 0;
  }

  .v-card + .v-card {
    margin-top: 1ch;
  }
</style>
